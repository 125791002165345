<template>
  <div v-if="data.category === '评论' && data.hasOwnProperty('evaluation') && data.evaluation.length >= 1">
    <p class="tag_group">
      <span>{{ data.hasOwnProperty('subject') ? data.subject : '' }}</span><span>{{ data.evaluation }}</span>
    </p>
  </div>
</template>
<script>
  export default {
    name: 'evaluation',
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data() {
      return {}
    },
    mounted() {
    },
    methods: {
    }
  }
</script>
<style scoped>
.tag_group span {padding-right:10px;font-size: 12px;color:rgba(65, 65, 65,.8);line-height: 40px;}
</style>
