<template>
  <el-container>
    <div class="scxx">
      <p>收藏信息至</p>
      <div class="h-160">
        <vue-scroll>
          <ul>
            <li class="cur-p" @click="addMaterial">
              <i class="iconfont icon-zengjia"></i>新建收藏夹
            </li>
            <li
              :class="m.id === selected.id ? 'cur-p active' : 'cur-p'"
              v-for="(m, i) in materials"
              :key="i"
              @click="selected.id = m.id">
              <i class="iconfont icon-shoucangjia"></i>{{ m.name }}
            </li>
          </ul>
        </vue-scroll>
      </div>
      <div class="button-group">
        <el-button
          v-loading="loading"
          :disabled="loading"
          @click="favorite()"
          type="primary"
          round>确定
        </el-button>
        <el-button round @click="cancel()">取消</el-button>
      </div>
    </div>
    <new-material :modal="modal" ref="addMaterial"/>
  </el-container>
</template>
<script type="application/javascript">
  import {mapGetters} from 'vuex';
  import newMaterial from '@components/common/new-material.vue';
  import {add_item} from "@/api/material";
  const _ = window._;
  export default {
    name: "favorite-dialog",
    props: {
      ids: {required: true},
      modal: {
        type: Boolean,
        default: true
      }
    },
    components: {
      newMaterial
    },
    data() {
      return {
        selected: {id: ''},
        loading: false
      }
    },
    computed: {
      ...mapGetters([
        'materials'
      ])
    },
    mounted() {
      this.selected = {id: ''};
      this.loading = false;
    },
    methods: {
      addMaterial() {
        this.$refs.addMaterial.show();
      },
      rowkeys() {
        const {ids} = this;
        if (_.isString(ids)) return ids;
        const rowkeys = [];
        if (_.isObject(ids)) {
          _(ids).forEach((v, k) => {
            if (v) rowkeys.push(k);
          })
        }
        return rowkeys.join(",");
      },
      cancel() {
        this.$emit('done');
      },
      favorite() {
        const ids = this.rowkeys();
        if (ids.length < 1) {
          this.$message.warning('请选择要添加的信息');
          return false;
        }
        const {selected} = this;
        if (!selected.id) {
          this.$message.warning('请选择收藏夹');
          return false;
        }
        this.loading = true;
        add_item({
          id: selected.id,
          ids: ids,
        }).then(res => {
          if (res.data.state) {
            this.$message({
              type: 'success',
              dangerouslyUseHTMLString: true,
              message: this.renderHtml(selected.id)
            });
            this.loading = false;
            // 收藏完成
            this.$emit('done');
          } else {
            this.$message.error(res.data.error);
          }
        }).catch(() => {
          this.$message.error('添加素材失败，服务错误');
        });
      },
      renderHtml(mid) {
        return (<span><span class="cor-67c">已收藏信息。</span><span class="cur-p underline cor-575" on-click={ () => {this.$router.push({name: 'material', params: {id: mid}})} }>查看收藏管理</span></span>);
      }
    }
  }
</script>
<style scoped>
/* 收藏信息弹出框 */
.scxx {padding: 10px 0;position: absolute;right:0;top: 30px;z-index: 10;background: #fff;box-shadow: 0px 2px 36px 0px rgba(65, 70, 76, 0.07);border-radius: 5px;}
.scxx {width: 180px;}
.scxx p {line-height: 24px;margin-bottom: 5px;font-size: 12px;padding:0 15px;}
.scxx li {line-height:30px;font-size: 12px;padding: 0 25px;cursor: pointer;}
.scxx li .iconfont {margin-right: 5px;}
.scxx li:hover .iconfont{color: #4E88FE;}
.scxx li:hover{background: rgba(78,136,254,0.1);color: #4E88FE;}
.h-160 {max-height: 160px;overflow: auto;}
.scxx .button-group {border-top: 1px solid #F2F2F2;margin-top: 10px;padding-top: 10px;}
.scxx >>> .el-button {width: 60px;height: 28px;line-height: 28px;}
.active {cursor: pointer;background: rgba(85, 93, 254, 0.1);color: #555DFE;}
.underline {
  text-decoration: underline;
}
</style>