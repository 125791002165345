<template>
  <el-container>
    <!-- 新建收藏夹 -->
    <el-dialog
      class="Dialog_style1 jxscj"
      :visible.sync="visible"
      :modal="modal"
      :close-on-click-modal="false"
    >
      <div slot="title" class="mod-title">新建收藏夹</div>
      <div class="cont">
        <div>
          <p>请输入收藏夹名称</p>
          <el-input
            class="w-310"
            type="text"
            placeholder="请输入收藏夹名称"
            v-model="name"
            maxlength="10"
            show-word-limit
          ></el-input>
        </div>
      </div>
      <div class="button-group">
        <el-button
          type="primary"
          round
          v-loading="loading"
          :disabled="loading"
          @click="add()"
          >新建</el-button
        >
        <el-button round @click="visible = false">取消</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>
<script type="application/javascript">
import { create as createMaterial } from '@/api/material';
export default {
  name: 'new-material',
  props: {
    modal: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      visible: false,
      name: '',
      loading: false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    show() {
      this.name = '';
      this.visible = true;
      this.loading = false;
    },
    add() {
      const { name } = this;
      if (name.length < 1) {
        this.$message.warning('请填写收藏夹名称');
        return false;
      }

      this.loading = true;
      createMaterial({ name })
        .then(res => {
          if (res.data.state) {
            this.$message.success('新增收藏夹成功');
            this.$store.dispatch('materialList');

            this.visible = false;
          } else {
            this.$message.error(res.data.error);
          }
          this.loading = false;
        })
        .catch(() => {
          this.$message.error('新增收藏夹失败，服务错误');
        });
    }
  }
};
</script>
<style scoped>
.jxscj .mod-title {
  margin-left: 20px;
  line-height: 14px;
}
.jxscj .cont {
  font-size: 14px;
}
</style>
