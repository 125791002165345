<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="detailDialog.visible"
      class="common-big-dialog common-detail-dialog"
    >
      <div slot="title" class="mod-title">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>信息列表</el-breadcrumb-item>
          <el-breadcrumb-item>信息详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div
        v-loading="detailDialog.loading"
        class="detailDialog h-100"
        :close-on-click-modal="false"
      >
        <div class="dialog-title">
          <el-row type="flex" justify="space-between">
            <el-col :span="18">
              <h3>
                <el-button
                  type="primary"
                  size="mini"
                  class="mg"
                  v-if="
                    row.emotion === 'negative' &&
                    detailDialog.detailFrom !== 'policy'
                  "
                  >敏感</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  class="fmg"
                  v-if="
                    row.emotion === 'positive' &&
                    detailDialog.detailFrom !== 'policy'
                  "
                  >非敏感</el-button
                >
                <span
                  class="iconfont icon-yuanfabiaoqian"
                  v-if="row.category == '微博' && row.isforward == 0"
                  >原</span
                >
                <span
                  class="iconfont icon-zhuanfabiaoqian"
                  v-if="row.category == '微博' && row.isforward == 1"
                  >转</span
                >
                <a class="title" v-html="row.title"></a>
              </h3>
            </el-col>
            <el-col :span="6">
              <ul class="ico-list">
                <li>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="屏蔽信源"
                    placement="bottom"
                  >
                    <i
                      :class="{
                        iconfont: true,
                        'icon-pingbixinyuan': true,
                        selected: row.shield,
                      }"
                      @click="shieldSource(row)"
                    ></i
                  ></el-tooltip>
                </li>
                <li v-show="showMark" class="" v-if="!row.markLoading">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="屏蔽信息"
                    placement="bottom"
                  >
                    <i
                      :class="{
                        iconfont: true,
                        'icon-pingbi': true,
                        selected: row.mark,
                      }"
                      @click="docMarkAction(row)"
                    ></i>
                  </el-tooltip>
                </li>
                <li class="mar-t-5" v-if="row.markLoading">
                  <i class="iconfont icon-Loading Rotation"></i>
                </li>
                <li class="pos-r">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="加入收藏夹"
                    placement="bottom"
                  >
                    <span class="cur-p" @click="favorite = !favorite">
                      <i
                        class="iconfont icon-shoucang selected"
                        v-if="favorite"
                      ></i>
                      <i class="iconfont icon-shoucang" v-else></i>
                    </span>
                  </el-tooltip>
                  <favorite-dialog
                    :ids="row.rowkey"
                    v-if="favorite"
                    @done="favorite = false"
                    :modal="false"
                  />
                </li>
                <li>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="查看原文"
                    placement="bottom"
                  >
                    <i
                      class="iconfont icon-lianjie"
                      @click="target(row.source_url)"
                    ></i>
                  </el-tooltip>
                </li>
              </ul>
            </el-col>
          </el-row>
          <p class="info mar-t-10">
            <span>媒体类型：{{ row.category }}</span>
            <span
              >来源：<var
                class="source_name"
                v-bind:title="
                  row.category === '微信' ? row.author : row.source_name
                "
                >{{
                  row.category === "微信" ? row.author : row.source_name
                }}</var
              >
            </span>
            <span v-if="row.author" class="author nowrap cur-p" :title='row.author.length >= 1 ? row.author : "--"'
              >作者：{{ row.author.length >= 1 ? row.author : "--" }}</span
            >
            <span v-if="row.category == '微博'"
              >文章属性：{{ row.isforward == 0 ? "原创" : "转发" }}</span
            >
            <span>信息地区：{{ msgArea(row) }}</span>
            <span>发布时间：{{ row.published }}</span>
            <span v-if="row.hasOwnProperty('aboutWord')">
              涉及词：{{
                row.aboutWord.length >= 1 ? row.aboutWord.join(",") : "--"
              }}
            </span>
          </p>
        </div>
        <div class="scroll-area">
          <!-- <vue-scroll> -->
          <div class="cont">
            <p class="ti-2em" v-html="row.content"></p>
            <div
              class="forward_container"
              v-if="row.category == '微博' && row.isforward == 1"
            >
              <span class="author">@{{ row.r_author }}：</span
              >{{ row.r_content }}
            </div>
            <evaluation :data="row" />
          </div>
          <!-- </vue-scroll> -->
        </div>
        <el-row class="button-group t-c">
          <el-button type="primary" round @click="detailDialog.visible = false"
            >返回列表</el-button
          >
        </el-row>
      </div>
    </el-dialog>
    <confirm
      ref="confirmShield"
      confirm="屏蔽"
      title="屏蔽信源"
      @must="mustShield"
      class="confirmShield"
    />
  </div>
</template>
<script>
import favoriteDialog from "@components/common/favorite-dialog.vue";
import evaluation from "@components/common/evaluation.vue";
import { index as messageIndex } from "@/api/message";
import { lists as materialList, add_item } from "@/api/material";
import { formatMsgArea } from "@/utils/helpers";
import { mapGetters } from "vuex";
import { docMark, cancelDocMark } from "@/api/message";
import { shieldSource, dropShieldSource, askSource_uuid } from "@/api/source";
import confirm from "@components/common/confirm";
export default {
  name: "dialog-article-detail",
  props: ["params", "materials"],
  data() {
    return {
      detailJoinMaterial: { selectedId: "", visible: false, loading: false },
      detailDialog: { visible: false, loading: false, detailFrom: "" },
      row: {},
      materialData: [],
      favorite: false,
      showMark: false,
      plan_id: null,
      currrentRow_uuid: "",
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    materials: function (data) {
      this.materialData = data;
    },
  },
  components: {
    evaluation,
    favoriteDialog,
    confirm,
  },
  mounted() {},
  methods: {
    shieldSource(row) {
      if (this.row.shield) {
        dropShieldSource({ uuid: this.currrentRow_uuid })
          .then((res) => {
            if (res.data.state) {
              this.$message.success("还原成功。");
              this.row.shield = false;
            } else {
              this.$message.error(res.data.error);
            }
          })
          .catch((e) => {
            window.console.error(e);
          });
      } else {
        const textBox = [
          {
            type: "error",
            text: `屏蔽后您将不会收到“${
              row.author || row.source_name || row.host
            }”发布的任何信息，确定屏蔽该信源？`,
          },
        ];
        this.$refs.confirmShield.show(textBox, row);
      }
    },
    mustShield(data) {
      const params = {
        category: data.category,
        username: data.username,
        site: "",
        author: data.author,
        host: data.host,
        source_name: data.source_name,
        userid: data.userid,
      };
      shieldSource(params)
        .then((res) => {
          if (res.data.state) {
            this.row.shield = true;
            this.currrentRow_uuid = res.data.data.uuid;
            this.$message({
              type: "success",
              dangerouslyUseHTMLString: true,
              message: this.renderShieldMsg(data),
            });
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((e) => {
          window.console.error(e);
          this.$message.error("屏蔽信源失败，服务错误");
        });
    },
    askIsset(data) {
      const params = {
        category: data.category,
        username: data.username,
        site: "",
        author: data.author,
        host: data.host,
        source_name: data.source_name,
        userid: data.userid,
      };
      askSource_uuid(params)
        .then((res) => {
          if (res.data.state) {
            if (res.data.data.isset == 1) {
              this.row.shield = true;
              this.currrentRow_uuid = res.data.data.uuid;
            } else {
              this.row.shield = false;
              this.currrentRow_uuid = "";
            }
          } else {
            console.log(res);
          }
        })
        .catch((e) => {
          window.console.error(e);
        });
    },
    renderShieldMsg(row) {
      return (
        <p>
          <span class="cor-67c">
            成功屏蔽“{row.author || row.source_name || row.host}
            ”，您将不会收到该信源发布的信息。
          </span>
          <span
            class="cur-p underline cor-575"
            on-click={() => {
              this.$router.push({ name: "shield", params: { shield: true } });
            }}
          >
            点击查看
          </span>
        </p>
      );
    },
    showDetail(
      rowkey,
      component = "",
      keywords = null,
      pid = null,
      isHotPointAction = false
    ) {
      this.favorite = false;
      this.detailDialog.visible = true;
      this.detailDialog.loading = true;
      this.detailJoinMaterial.visible = false;
      this.row = {};
      this.detailDialog.detailFrom = component;
      let params = { id: rowkey };
      if (keywords && _.size(keywords) >= 1) {
        params.highlight = keywords.join(",");
      }
      if (this.params) {
        if (this.params.focus != undefined) params.focus = this.params.focus;
        if (this.params.highlight != undefined)
          params.highlight =
            params.highlight == undefined
              ? this.params.highlight
              : params.highlight + "," + this.params.highlight;
        if (this.params.plan_id) params.plan_id = this.params.plan_id;
      }
      if (pid) {
        params.plan_id = pid;
      }
      this.showMark = params.plan_id ? true : false;
      this.plan_id = params.plan_id;
      messageIndex(params)
        .then((res) => {
          if (res.data.state) {
            var result = res.data.data;
            result.mark =
              result.hasOwnProperty("uid") &&
              result.uid.indexOf(this.user.id) !== -1; // 文档是否被标记
            result.markLoading = false;
            result.shield = false;
            this.row = result;
            //2015-5-18 屏蔽信源bug
            if (isHotPointAction) {
              this.askIsset(this.row);
            }
            this.detailDialog.loading = false;
            if (!this.materials) this.loadMaterial();
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch(() => {
          this.$message.error("加载数据详情错误");
        });
    },
    docMarkAction(item) {
      item.markLoading = true;
      if (item.mark) {
        // 取消文档标记
        cancelDocMark({ rowkey: item.rowkey })
          .then((res) => {
            if (res.data.state) {
              this.$message("信息恢复为精准信息");
              item.mark = false;
            } else {
              this.$message.error(res.data.error);
            }
            item.markLoading = false;
          })
          .catch((e) => {
            item.markLoading = false;
            window.console.error(e);
          });
      } else {
        // 进行文档标记
        docMark({ plan_id: this.plan_id, rowkey: item.rowkey, msg: "" })
          .then((res) => {
            if (res.data.state) {
              this.$message({
                type: "success",
                dangerouslyUseHTMLString: true,
                message: this.renderMarkMsgHtml(),
              });
              item.mark = true;
            } else {
              this.$message.error(res.data.error);
            }
            item.markLoading = false;
          })
          .catch((e) => {
            item.markLoading = false;
            window.console.error(e);
          });
      }
    },
    renderMarkMsgHtml() {
      return (
        <p>
          <span class="cor-67c">信息已被屏蔽成功。</span>
          <span
            class="cur-p underline cor-606"
            on-click={() => {
              this.$router.push({ name: "shield", params: {} });
            }}
          >
            点击查看
          </span>
        </p>
      );
    },
    msgArea(row) {
      return formatMsgArea(row);
    },
    target(url) {
      window.open(url, "_blank");
    },
    addRowMaterialAction(material, id) {
      if (!material.selectedId) {
        this.$message.warning("请选择收藏夹");
        return false;
      }
      material.loading = true;
      add_item({ id: material.selectedId, ids: id })
        .then((res) => {
          if (res.data.state) {
            this.$message.success("添加成功");
          } else {
            this.$message.error(res.data.error);
          }
          material.loading = false;
          material.visible = false;
        })
        .catch((res) => {
          this.$message.error("添加素材失败，服务错误");
        });
    },
    loadMaterial() {
      materialList()
        .then((res) => {
          if (res.data.state) {
            var result = res.data.data;
            this.materialData = result;
          } else {
            this.$message.error(res.data.error);
          }
        })
        .catch((res) => {
          this.$message.error("加载素材库失败，服务错误");
        });
    },
  },
};
</script>
<style scoped>
.dialog-title {
  border-bottom: 1px solid #f2f2f2;
  padding: 15px 0 10px;
}
.dialog-title h3 {
  line-height: 24px;
}
.dialog-title .title {
  font-weight: 600;
  font-size: 14px;
  width: calc(100% - 100px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
  cursor: pointer;
}
.dialog-title .ico-list > li {
  float: right;
  margin-left: 10px;
}
.dialog-title .summary {
  font-size: 12px;
  line-height: 24px;
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #414141;
  margin-top: 10px;
}
.dialog-title .info,
.dialog-title .info .source_name {
  line-height: 24px;
  font-size: 12px;
  color: #707070;overflow: hidden;
}
.dialog-title .info span {
  margin-right: 20px;
  float: left;
}
.dialog-title .info .author{width: 100px;}
.common-big-dialog .scroll-area {
  height: calc(100% - 160px);
}
.common-detail-dialog .cont {
  padding: 10px 20px 30px 0;
  font-size: 12px;
  line-height: 24px;
  white-space: pre-wrap;
}
</style>
